import React, { useState, useEffect } from "react"
import { Button, Carousel, Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Index"
import VerticalAds from "../components/commons/ads/vertical-ads/Index"
import HorizontalAds from "../components/commons/ads/horizontal-ads/Index"
import { Link, navigate, graphql } from "gatsby"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { withTrans } from "../i18n/withTrans"
import { inRangeDate } from "../utils/date"
import moment from "moment"
import ReactPlayer from "react-player/youtube"
import MultiCarousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const Index = ({ t, data, i18n, location: { origin } }) => {
  const language = i18n.language
  const [indexPartner, setIndexPartner] = useState(0)
  const [totalAnggota, setTotalAnggota] = useState(0)
  const [totalWilayah, setTotalWilayah] = useState(0)
  const [dataYoutube, setDataYoutube] = useState(null)

  const dataBerita = data?.allWpBerita?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataAgenda = data?.allWpKegiatan?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataGallery = data?.allWpGalleryGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataArticle = data?.allWpArtikel?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanPremium = dataIklan?.filter(
    o => o.iklan.slotIklan === "Premium (1100 x 510 px)"
  )
  const dataIklanGoldA = dataIklan?.filter(
    o => o.iklan.slotIklan === "Gold-A (1060 x 150 px)"
  )
  const dataIklanSilver = dataIklan?.filter(
    o => o.iklan.slotIklan === "Silver Ad Space (1060 x 150 px)" && o.iklan.letakIklan.silverAdSpace === "Beranda"
  )
  const dataPartner = data?.allWpPartner?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const lengthPartner = 4
  const totalIndexPartner = dataPartner
    ? Math.ceil(dataPartner.length / lengthPartner)
    : 0
  const dataBanner = data?.allWpBannerGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )

  const arrTotal = Array.from({ length: totalIndexPartner }, (v, i) => i)

  const regexGetHrefAttribute = /<a[^>]*href=["']([^"']*)["']/gm
  const getGalleryData = wysiwygMultipleGallery => {
    const getHrefAttribute = wysiwygMultipleGallery?.match(
      regexGetHrefAttribute
    )
    const getAllGalleryLink = getHrefAttribute?.map(
      item => `${item.substring(9, item.length - 1).replace(/ /g, "%20")}`
    )
    return getAllGalleryLink
  }

  const getGalleryValue = getGalleryData(
    dataGallery[0]?.gallery.editorRegGambar
  )

  const handlePartner = (selectedIndex, e) => {
    setIndexPartner(selectedIndex)
  }

  function parseHtml  (htmlString) {
    let new_string = htmlString.replace(/<\/?[^>]+(>|$)/g, "")
    return new_string.substring(0, 100) ;
  };

  useEffect(() => {
    getMember()
    getRegion()
    getYoutube()
  }, [])

  const getMember = () => {
    fetch(`${process.env.GATSBY_API_URL}/member`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setTotalAnggota(result.recordsTotal)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getRegion = () => {
    fetch(`${process.env.GATSBY_API_URL}?length=99999`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setTotalWilayah(result.recordsTotal)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getYoutube = () => {
    let channelID = 'UCatsH1lZp9S9o5N5M87u_BQ'
    fetch(`https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D${channelID}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setDataYoutube(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1240 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1240, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }

  const responsiveBanner = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }

  return (
    <Layout>
      <div className="remove-padding home-page mini-home-page">
        <Container>
          <div className="hero-section">
            <MultiCarousel responsive={responsiveBanner} className="home">
              <Row className="beranda-welcome-msg">
                <Col lg={{ span: 5, offset: 1 }} className="hero-content">
                  <label className="welcome-msg-title">
                    {t("home.welcomeTitle")}
                  </label>
                  <p className="welcome-msg">{t("home.welcomeMessage")}</p>
                  <div className="hero-container">
                    <div className="hero-data d-flex justify-content-between">
                      <div className="detail-data">
                        <span>{totalAnggota}</span>
                        <span>{t("home.activeMember")}</span>
                      </div>
                      <div className="detail-data">
                        <span>{totalWilayah}</span>
                        <span>{t("home.regionalBranch")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="profit-container">
                    <div className="profit d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div>
                          <StaticImage
                            src="../../static/images/header_logo.png"
                            alt="header-logo"
                            placeholder="blurred"
                            height={80}
                            width={100}
                          />
                        </div>
                        <Col>
                          <div>
                            <span style={{ fontWeight: 700, fontSize: 19 }}>
                              GAKESLAB
                            </span>
                          </div>
                          <div>
                            <span>INDONESIA</span>
                          </div>
                        </Col>
                      </div>
                      <StaticImage
                        src="../../static/images/profit_banner.png"
                        alt="header-logo"
                        placeholder="blurred"
                        height={60}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="hero-img">
                  <StaticImage
                    src="../../static/images/img_welcome-home.png"
                    alt="welcome-img"
                    placeholder="blurred"
                    className="welcome-img"
                    height={504}
                    width={477}
                  />
                </Col>
              </Row>
              <div className="m-auto" style={{ maxWidth: 1100 }}>
                {dataIklanPremium?.length > 0 &&
                inRangeDate(
                  dataIklanPremium[0].iklan.tanggalJangkaIklanAwal,
                  dataIklanPremium[0].iklan.tanggalJangkaIklanAkhir
                ) &&
                dataIklanPremium[0].iklan.uploadGambarIklanPremium ? (
                  <VerticalAds
                    width={1100}
                    height={510}
                    img_url={
                      dataIklanPremium[0].iklan.uploadGambarIklanPremium
                        .sourceUrl
                    }
                    title="A. PREMIUM AD SPACE"
                  />
                ) : (
                  <VerticalAds
                    width={1100}
                    height={510}
                    title="A. PREMIUM AD SPACE"
                  />
                )}
              </div>
              {dataBanner.length > 0 &&
                dataBanner.map((item, index) => (
                  <div
                    className="m-auto"
                    style={{ maxWidth: 1100 }}
                    key={index}
                  >
                    <img
                      src={item.banner.uploadBannerImg.sourceUrl}
                      alt="banner"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ))}
            </MultiCarousel>
            <MultiCarousel responsive={responsiveBanner} className="home iklan-gold-a-beranda" arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={7000}>
              {dataIklanGoldA?.length > 0 ? (
                dataIklanGoldA?.filter(word => inRangeDate(word.iklan.tanggalJangkaIklanAwal, word.iklan.tanggalJangkaIklanAkhir)).length > 0 ? (
                  dataIklanGoldA.map((item, index) => {
                    let checkDate = inRangeDate(
                      item.iklan.tanggalJangkaIklanAwal,
                      item.iklan.tanggalJangkaIklanAkhir
                    )
                    if (checkDate) {
                      if (item.iklan.uploadGambarIklanGoldA != null){
                        return(
                          <div className="hero-ads">
                            <HorizontalAds
                              radius={10}
                              width={1060}
                              height={150}
                              img_url={
                                item.iklan.uploadGambarIklanGoldA.sourceUrl
                              }
                              title="A. GOLD AD SPACE"
                              isBgPrimary
                            />
                          </div>
                        )
                      }
                    }
                  })
                ) : (
                  <div className="hero-ads">
                    <HorizontalAds
                      width={1060}
                      height={150}
                      title="A. GOLD AD SPACE"
                      isBgPrimary
                    />
                  </div>
                )
              ) 
              : (
                <div className="hero-ads">
                  <HorizontalAds
                    width={1060}
                    height={150}
                    title="A. GOLD AD SPACE"
                    isBgPrimary
                  />
                </div>
              )}
            </MultiCarousel>
          </div>
          <div className="video-section">
            {/* <div className="title">
              <label>{t("home.profileGakeslab")}</label>
            </div> */}
            <Row className="justify-content-center mt-5">
              <Col md={8} sm={10} xs={12} className="video-profile" style={{ marginTop: 70 }}>
                {dataYoutube && dataYoutube?.items[0].link ? (
                  <ReactPlayer
                    className="video-intro"
                    // width="100%"
                    // height={460}
                    controls
                    url={dataYoutube?.items[0].link}
                  />
                ) : ''}
                <StaticImage
                  src="../../static/images/ormamen_video_1.png"
                  alt="ormamen_video_1"
                  className="ormamen_video_1"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ormamen_video_3.png"
                  alt="ormamen_video_2"
                  className="ormamen_video_2"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ormamen_video_2.png"
                  alt="ormamen_video_3"
                  className="ormamen_video_3"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ormamen_video_2.png"
                  alt="ormamen_video_4"
                  className="ormamen_video_4"
                  placeholder="blurred"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <div className="partner-section">
            <div className="title" style={{ marginBottom: 70 }}>
              <label>{t("home.ourPartners")}</label>
              <label>{t("home.partnershipMessage")}</label>
            </div>
            {dataPartner &&
              (dataPartner.length > 4 ? (
                <MultiCarousel
                  responsive={responsive}
                  // customRightArrow={<CustomRightArrow />}
                  // customLeftArrow={<CustomLeftArrow />}
                >
                  {dataPartner.map((item, index) => (
                    <div className="d-flex justify-content-center">
                      <img
                        src={item.partner.logoPartner?.sourceUrl}
                        alt="partner-img"
                        height={56}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item.partner.urlPartner, "_blank")
                        }
                      />
                    </div>
                  ))}
                </MultiCarousel>
              ) : (
                <div className="custom-carousel">
                  <div className="d-flex justify-content-around">
                    {dataPartner.map((item, index) => (
                      <img
                        src={item.partner.logoPartner?.sourceUrl}
                        alt="partner-img"
                        height={56}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item.partner.urlPartner, "_blank")
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Container>
        <div className="sambutan-galeri-section-parent">
          <div className="sambutan-galeri-section">
            <Row className="sambutan-section d-flex justify-content-center">
              <Col md={6} className="sambutan-image">
                <StaticImage
                  src="../../static/images/ketum_gakeslab_2023.png"
                  alt="RD Kartono Dwidjosewojo"
                  className="ltr-image"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ornamen_2.png"
                  alt="ornamen-2"
                  className="ornamen-2"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ornamen_1.png"
                  alt="ornamen-1"
                  className="ornamen-1"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../static/images/ornamen_3.png"
                  alt="ornamen-3"
                  className="ornamen-3"
                  placeholder="blurred"
                />
              </Col>
              <Col md={6} style={{ zIndex: 1 }} className="sambutan-menu">
                <div className="sambutan-desc">
                  <span>RD Kartono Dwidjosewojo</span>
                  <h3>{t("home.welcomingTitle")}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataArticle ? dataArticle[0]?.content : "",
                    }}
                  />
                  {/* <p>{t("home.welcomingMessage")}</p> */}
                </div>
                <div className="customButtonViewSambutan">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      let url =
                        "berita/artikel/" +
                        (dataArticle ? dataArticle[0]?.slug : "")
                      navigate(url)
                    }}
                  >
                    {t("home.viewMore")}
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="gallery-home-container">
              <div className="top-galeri-beranda mini-hide" />
              <Row className="gallery-section">
                <Col md={{ span: 4, offset: 1 }}>
                  {dataGallery.length > 0 && (
                    <div className="gallery-desc">
                      <h3>{t("home.gallery")}</h3>
                      <p>{dataGallery[0]?.title}</p>
                      <img
                        className="thumbnail dashboard-image mini-show mini-show-gallery-beranda"
                        src={getGalleryValue ? getGalleryValue[0] : ""}
                        alt=""
                      />
                      <Button
                        variant="outline-light"
                        onClick={() => navigate("/kegiatan/galeri")}
                      >
                        {t("home.seeGallery")}
                      </Button>
                    </div>
                  )}
                </Col>
                <Col
                  md={7}
                  style={{ alignSelf: "center", alignItem: "center" }}
                >
                  <div
                    className={
                      getGalleryValue?.length > 1
                        ? "gallery-image-multiple mini-hide-gallery-beranda"
                        : "gallery-image mini-hide-gallery-beranda"
                    }
                  >
                    {/* gallery */}
                    {getGalleryValue && getGalleryValue[0] && (
                      <div className="gallery-image-1">
                        <img
                          className="thumbnail"
                          style={{}}
                          src={getGalleryValue[0]}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="description-img-container">
                      <div className="gallery-description">
                        <p
                          style={{
                            fontSize: 12,
                            color: "black",
                            opacity: "0.8",
                          }}
                        >
                          {dataGallery[0]?.gallery.tanggalRegulasi}
                        </p>
                        <Link to={`/kegiatan/galeri/${dataGallery[0]?.slug}`}>
                          {dataGallery[0]?.title}
                        </Link>
                      </div>
                    </div>
                    {getGalleryValue && getGalleryValue[1] && (
                      <div className="gallery-image-2">
                        <img
                          className="thumbnail"
                          style={{ backgroundColor: "white" }}
                          src={getGalleryValue[1]}
                          alt=""
                        />
                      </div>
                    )}
                    {getGalleryValue && getGalleryValue[2] && (
                      <div className="gallery-image-3">
                        <img
                          className="thumbnail"
                          style={{}}
                          src={getGalleryValue[2]}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="gallery-ornamen mini-hide" />
                    <div className="gallery-ornamen-2 mini-hide" />
                  </div>
                </Col>
              </Row>
              <div className="bottom-galeri-beranda mini-hide" />
            </div>
          </div>
        </div>
        <Container>
          <Row className="event-section">
            <Col md={6} className="p-0">
              <div className="head">
                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <label className="title">
                      {t("home.newestEvent") + moment().year()}
                    </label>
                  </Col>
                  <Col md={6} sm={6} xs={12}>

                    <Button
                      variant="outline-primary"
                      onClick={() => navigate("/agenda")}
                    >
                      {t("home.seeAllEvent")}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="event-highlight mini-show mb-3">
                <div className="selected-event">
                  <div className="h-75">
                    <h3
                      className="title"
                      onClick={() => navigate(`/agenda/${dataAgenda[0]?.slug}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {dataAgenda && dataAgenda.length > 0
                        ? dataAgenda[0]?.title.length > 50
                          ? dataAgenda[0].title.substring(0, 50) + " ..."
                          : dataAgenda[0]?.title
                        : ""}
                    </h3>
                  </div>
                  <span className="date" style={{ marginBottom: 10 }}>
                    {dataAgenda && dataAgenda.length > 0
                      ? moment(
                          dataAgenda[0]?.event.tanggalKegiatan?.tanggalMulai,
                          "DD/MM/YYYY"
                        ).format("DD MMMM YYYY")
                      : ""}
                  </span>
                </div>
              </div>
              {dataAgenda.length > 0 &&
                dataAgenda.slice(1, 4).map((item, i) => {
                  let date = moment(
                    item.event.tanggalKegiatan?.tanggalMulai,
                    "DD/MM/YYYY"
                  ).format("MMMM")
                  let day = moment(
                    item.event.tanggalKegiatan?.tanggalMulai,
                    "DD/MM/YYYY"
                  ).format("DD")

                  return (
                    <Row className="event-list mx-0" key={i}>
                      <Col
                        md={2}
                        xs={2}
                        className="date d-flex flex-column ps-0"
                      >
                        <span className="month">{date}</span>
                        <span className="day">{day}</span>
                      </Col>
                      <Col md={10} xs={10} className="title ps-4">
                        <span>
                          <Link to={"/agenda/" + item.slug}>{item.title}</Link>
                          {/* Train of The Trainer - Penerapan Cara Distribusi Alat
                          Kesehatan yang Baik (CDAKB) */}
                        </span>
                      </Col>
                    </Row>
                  )
                })}
            </Col>
            <Col
              md={{ span: 5, offset: 1 }}
              className="event-highlight d-flex flex-column event-highlight-show"
            >
              <div className="selected-event">
                <div className="h-75">
                  <h3
                    className="title"
                    onClick={() => navigate(`/agenda/${dataAgenda[0]?.slug}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {dataAgenda && dataAgenda.length > 0
                      ? dataAgenda[0]?.title.length > 50
                        ? dataAgenda[0].title.substring(0, 50) + " ..."
                        : dataAgenda[0]?.title
                      : ""}
                  </h3>
                </div>
                <span className="date" style={{ marginBottom: 10 }}>
                  {dataAgenda && dataAgenda.length > 0
                    ? moment(
                        dataAgenda[0]?.event.tanggalKegiatan?.tanggalMulai,
                        "DD/MM/YYYY"
                      ).format("DD MMMM YYYY")
                    : ""}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="p-0">
          <Col className="information-section">
            <div className="head d-flex justify-content-between p-0">
              <h3 className="title">{t("home.newInformation")}</h3>
              <Button
                variant="outline-primary"
                onClick={() => navigate("/berita")}
              >
                {t("home.viewAll")}
              </Button>
            </div>
            {/* <Carousel>
              <Carousel.Item className="d-flex flex-row justify-content-between"> */}
            <Row className="justify-content-around p-0">
              {dataBerita.length > 0 &&
                dataBerita.slice(0, 3).map((item, i) => {
                  return (
                    <Col
                      md={4}
                      sm={6}
                      xs={12}
                      className="information-list px-1"
                    >
                      <img
                        src={
                          item?.news?.thumbnail?.sourceUrl
                            ? item.news.thumbnail.sourceUrl
                            : "../../../../images/Img_default.png"
                        }
                        alt="information-img"
                        placeholder="blurred"
                        className="information-img"
                        height={250}
                        // width={400}
                        width="100%"
                      />
                      <span className="date">{item.date}</span>
                      <h4 className="title">
                        {/* {item?.title?.length > 50
                          ? item?.title?.substring(0, 50) + "..."
                          : item?.title} */}
                        {item?.title}
                      </h4>
                      <p>{item?.content? parseHtml(item.content)+"..." : ''}</p>
                      <Link to={"/berita/" + item.slug}>
                        {t("home.more")} <ArrowRightAltIcon />
                      </Link>
                    </Col>
                    // </div>
                  )
                })}
            </Row>
            {/* </Carousel.Item>
            </Carousel> */}
          </Col>
        </Container>
        <Container className="p-0">
          <MultiCarousel responsive={responsiveBanner} className="home" arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={7000}>
            {dataIklanSilver?.length > 0 ? (
              dataIklanSilver?.filter(word => inRangeDate(word.iklan.tanggalJangkaIklanAwal, word.iklan.tanggalJangkaIklanAkhir)).length > 0 ? (
                dataIklanSilver.map((item, index) => {
                  let checkDate = inRangeDate(
                    item.iklan.tanggalJangkaIklanAwal,
                    item.iklan.tanggalJangkaIklanAkhir
                  )
                  if (checkDate) {
                    return(
                      <div className="d-flex justify-content-center">
                        <HorizontalAds
                          width={1060}
                          height={150}
                          title="SILVER AD SPACE"
                          img_url={
                            item.iklan.uploadGambarIklanSilverAdSpace.sourceUrl
                          }
                        />
                      </div>
                    )
                  }
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <HorizontalAds
                    width={1060}
                    height={150}
                    title="SILVER AD SPACE"
                  />
                </div>
              )
            ) 
            : (
              <div className="d-flex justify-content-center">
                <HorizontalAds
                  width={1060}
                  height={150}
                  title="SILVER AD SPACE"
                />
              </div>
            )}
          </MultiCarousel>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpKegiatan(sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        id
        slug
        date(formatString: "MMMM DD, YYYY")
        language {
          id
          code
        }
        title
        event {
          tanggalKegiatan {
            tanggalMulai
            tanggalBerakhir
          }
          tanggalRegistrasi {
            tanggalMulai
            tanggalBerakhir
          }
          regionEvent
          imgName {
            sourceUrl
          }
          activityAddress
          description
          kontakPerson {
            nama
            noTelepon
          }
        }
      }
    }
    allWpBerita(sort: { fields: date, order: DESC }) {
      nodes {
        id
        news {
          thumbnail {
            sourceUrl
          }
        }
        language {
          code
        }
        date(formatString: "MMMM DD, YYYY")
        id
        slug
        uri
        title
        content
        link
      }
      totalCount
    }
    allWpGalleryGks(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        language {
          code
        }
        gallery {
          tanggalRegulasi
          editorRegGambar
        }
      }
    }
    allWpArtikel(filter: { id: { in: ["cG9zdDo1NTg=", "cG9zdDo1NTQ="] } }) {
      nodes {
        date(formatString: "MMMM DD, YYYY")
        language {
          code
        }
        article {
          thumbnail {
            sourceUrl
          }
        }
        id
        slug
        uri
        title
        content
        link
      }
    }
    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          uploadGambarIklanGoldA {
            sourceUrl
          }
          uploadGambarIklanGoldB {
            sourceUrl
          }
          uploadGambarIklanPremium {
            sourceUrl
          }
          uploadGambarIklanSilverAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
    allWpPartner(sort: { fields: title }) {
      nodes {
        partner {
          urlPartner
          logoPartner {
            sourceUrl
          }
        }
        language {
          code
        }
      }
    }
    allWpBannerGks(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        language {
          code
        }
        banner {
          uploadBannerImg {
            sourceUrl
          }
        }
      }
    }
  }
`

export default withTrans(Index)
